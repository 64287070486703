import React, { useState } from "react";
import NavBar from "../../components/shared/NavBar";
import Footer from "../../components/shared/Footer";
import { Button, Col, Container, Form, Image, InputGroup, Row, Spinner } from "react-bootstrap";
import shoppingImage from '../../assets/beatsnoop.png'
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import BASE_URL from "../../config";

const Login = () => {
    const navigator = useNavigate()
    const location = useLocation()
    console.log(location)
    if (location.state) {
        var message = location.state.message
        setTimeout(() => {
            location.state.message = ''
        }, 2000)
    } else {
        message = ''
    }
    const [inputValue, setInputValue] = useState('')
    const [errorMessage, setErrorMessage] = useState(message)
    const [disableBtn, setDisableBtn] = useState(false)
    const [successMessage, setSuccessMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState({
        email: '',
        phone: '',
        password: ''
    })

    const handleInput = (e) => {
        const {name, value} = e.target
        setFormData({...formData, [name]: value})
    }
    const handleMailOrPhone = (e) => {
        const {value} = e.target
        setInputValue(value)
        const isEmail = /\S+@\S+\.\S+/.test(value)
        const isPhoneNumber = /^((\+?\d{1,3})|[0])\d{10}$/.test(value)
        if (isEmail) {
            setFormData({...formData, email: value})
        }
        if (isPhoneNumber) {
            setFormData({...formData, phone: value})
        }
        if (!isEmail && !isPhoneNumber){
            setErrorMessage('invalid email or phone number')
            setDisableBtn(true)
        } else {
            setErrorMessage('')
            setDisableBtn(false)
        }

    }
    const handleSubmit = async(e) => {
        e.preventDefault()
        setErrorMessage('')
        setLoading(true)
        await axios.post(`${BASE_URL}/auth/login`, formData)
                    .then((response) => {
                        localStorage.setItem('token', response.data.token)
                        const payload = response.data.payload
                        localStorage.setItem('user', JSON.stringify(payload))
                        localStorage.setItem('isLoggedIn', true)
                        setLoading(false)
                        setSuccessMessage(response.data.message)
                        setTimeout(() => {
                                navigator('/')
                        }, 3000)
                    })
                    .catch((error) => {
                        setLoading(false)
                        setErrorMessage(error.response?.data)
                    })
    }
    return (
        <>
        <NavBar />
        <Container>
            <Row className="d-flex flex-row gap-3 m-3 align-items-center justify-content-xl-between">
                <Col xl={5} lg={5} className="">
                    <Image 
                    src={shoppingImage}
                    alt="Shopping Image"
                    className="align-self-center w-100"
                    />
                </Col>
                <Col xl={6} lg={6} className="d-flex flex-column justify-content-center">
                    <h3 className="text-left">
                        Login to Mummy Flourish Savings
                    </h3>
                    <p className="text-left">
                        Enter your details below
                    </p>
                    <Form className="d-flex flex-column align-items-center"
                    onSubmit={handleSubmit}
                    >
                        <Form.Control 
                            type="text"
                            name="inputValue"
                            placeholder="Email or Phone number"
                            className="form-input my-3" 
                            autoComplete="username"
                            value={inputValue}
                            onChange={handleMailOrPhone}
                            required
                        />
                        <Form.Control 
                            type="password"
                            name="password"
                            placeholder="Password"
                            className="form-input my-3"
                            autoComplete="current-password"
                            value={formData.password}
                            onChange={handleInput}
                            required
                        />
                        {
                            loading && (
                                <Spinner animation="border" role="status" className="d-flex align-self-center justify-content-center ">
                                    <span className="visually-hidden"  >Loading...</span>
                                </Spinner>
                            )
                        }
                        <i className="text-danger">{errorMessage || message.toString()}</i>
                        <i className="text-success">{successMessage}</i>
                        <InputGroup className="align-items-center justify-content-between ">
                        <Button className="btn-danger my-2 mx-2 w-50" type="submit" disabled={disableBtn}>Login</Button>
                        <a className="text-center mx-2 text-danger text-decoration-none" href="/forgot-password">forgot password</a>
                        </InputGroup>
                    </Form>
                    <Button
                    className="btn bg-transparent border-1 border-black text-black my-2 w-100 align-self-center">
                        Login with Google
                    </Button>
                    <p className="text-center">Don't have an account
                        <a href="/signup" className="text-black mx-2">Sign Up</a>
                    </p>
                </Col>
            </Row>
        </Container>
        <Footer />
        </>
    )
}

export default Login