import React from "react";
import NavBar from "../../components/shared/NavBar";
import Footer from "../../components/shared/Footer";
import Wishlist from "../../components/user/Wishlist";

const WishListPage = () => {
    return (
        <>
        <NavBar />
        <Wishlist />
        <Footer />
        </>
    )
}

export default WishListPage