import { FaRegEye, FaArrowRight, FaArrowLeft  } from "react-icons/fa6"
import { FaRegHeart, FaStar, FaApple  } from "react-icons/fa"
import { IoIosSearch } from "react-icons/io"
import { FiShoppingCart } from "react-icons/fi";
import { RiDeleteBin6Line } from 'react-icons/ri'
import { MdOutlineShoppingCart } from 'react-icons/md'
import { CgProfile } from "react-icons/cg";

export const Icons = {
    FaRegEye,
    FaRegHeart,
    FaStar,
    FaArrowRight,
    FaArrowLeft,
    IoIosSearch,
    FiShoppingCart,
    FaApple ,
    MdOutlineShoppingCart,
    RiDeleteBin6Line,
    CgProfile
}