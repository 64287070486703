import React, { createContext, useEffect, useReducer } from "react";
import axios from "axios";
import BASE_URL from '../config.js'

export const ProductContext = createContext()
const initialState = {
    products: [],
    error: null
}
const ProductReducer = (state, action) => {
    switch (action.type) {
        case 'SET_PRODUCT':
            return {
                ...state,
                products: action.payload,
                error: null
            }
        case 'SET_ERROR':
        return {
            ...state,
            error: action.payload?.data || "An error occurred while fetching products",
        };
        default:
            return state
    }
}
export const ProductProvider = ({children}) => {
    const [state, dispatch] = useReducer(ProductReducer, initialState)
    const fetchProducts = () => {
        axios.get(`${BASE_URL}/product`)
                    .then((response) => {
                        dispatch({
                            type: 'SET_PRODUCT',
                            payload: response.data.products
                        })
                    })
                    .catch((error) => {
                        console.log(error.response?.data)
                    })
    }
    useEffect(() => {
        fetchProducts()
    }, [])
    return (
        <ProductContext.Provider
        value={{
            ...state,
            fetchProducts
        }}
        >
            {children}
        </ProductContext.Provider>
    )
}

export default ProductProvider