import {ListGroup, Container, Row, Col, Carousel, Image } from 'react-bootstrap'
import heroImage from '../../assets/hero_endframe.png'
import { Icons } from "../../icons";
import SideBar from "./SideBar";

const { FaArrowRight, FaApple  } = Icons

const Hero = () => {
    return (
        <Container className="mx-auto my-3">
            <Row>
                <Col xl={2}>
                        <SideBar />
                </Col>
                <Col>
                    <Carousel className="bg-black">
                        <Carousel.Item>
                            <Row>
                                <Col className="d-flex align-items-center ps-5">
                                    <ListGroup className="align-items-left bg-black">
                                        <ListGroup.Item className="bg-black text-white">
                                            <FaApple />
                                            <span className="mx-3">iphone 14 Stores</span>
                                        </ListGroup.Item> 
                                        <ListGroup.Item className="bg-black text-white">
                                            <h3>Up to 10% off Voucher</h3>
                                        </ListGroup.Item>
                                        <ListGroup.Item className="bg-black text-white">
                                            <a href="#home" className="me-2 text-white">
                                            <span className="me-3">Shop Now </span>
                                            <FaArrowRight />
                                            </a>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Col>
                                <Col className="mt-5">
                                    <Image 
                                    className="d-block w-100"
                                    src={heroImage}
                                    alt="Hero Image 1"
                                    />
                                </Col>
                            </Row>
                        </Carousel.Item>
                        <Carousel.Item>
                            <Row>
                                <Col className="d-flex align-items-center ps-5">
                                    <ListGroup className="align-items-left bg-black">
                                        <ListGroup.Item className="bg-black text-white">
                                            <FaApple />
                                            <span className="mx-3">iphone 14 Stores</span>
                                        </ListGroup.Item> 
                                        <ListGroup.Item className="bg-black text-white">
                                            <h3>Up to 10% off Voucher</h3>
                                        </ListGroup.Item>
                                        <ListGroup.Item className="bg-black text-white">
                                            <a href="#home" className="me-2 text-white">
                                            <span className="me-3">Shop Now </span>
                                            <FaArrowRight />
                                            </a>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Col>
                                <Col className="mt-5">
                                    <Image 
                                    className="d-block w-100"
                                    src={heroImage}
                                    alt="Hero Image 1"
                                    />
                                </Col>
                            </Row>
                        </Carousel.Item>
                    </Carousel>       
                </Col> 
            </Row>
        </Container>
    )
}

export default Hero