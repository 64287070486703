import React from "react";
import { Container, Image, Row, Col, ListGroup } from "react-bootstrap";
import deliveryIcon from '../../assets/icon-delivery.png'
import customerServiceIcon from '../../assets/Icon-Customer-service.png'
import iconSecure from '../../assets/Icon-secure.png'

const BeforeFooter = () => {
    return (
        <Container>
            <Row className="my-3 d-flex justify-content-between">
                <Col className="d-flex align-self-center text-center">
                <ListGroup className="d-flex align-items-center">
                        <ListGroup className="rounded-circle bg-secondary align-items-center" 
                        style={{width: '80px', height: '80px'}}
                        >
                            <ListGroup.Item className="rounded-circle bg-black m-2" 
                            style={{width: '67px', height: '80px'}}>
                        <Image
                        src={deliveryIcon}
                        alt="delivery logo"
                        style={{width: '40px', height: '40px'}}
                        />
                    </ListGroup.Item>
                    </ListGroup>
                    <ListGroup.Item>
                    <h5>FREE AND FAST DELIVERY</h5>
                    <p>Free delivery for all orders over $140</p>
                    </ListGroup.Item>
                    </ListGroup>
                </Col>
                <Col className="d-flex align-self-center text-center">
                    <ListGroup className="d-flex align-items-center">
                        <ListGroup className="rounded-circle bg-secondary align-items-center" 
                        style={{width: '80px', height: '80px'}}>
                            <ListGroup.Item className="rounded-circle bg-black m-2" 
                            style={{width: '67px', height: '80px'}}>
                                <Image
                                src={customerServiceIcon}
                                alt="delivery logo"
                                style={{width: '40px', height: '40px'}}
                                />
                            </ListGroup.Item>
                        </ListGroup>
                        <ListGroup.Item>
                        <h5>24/7 CUSTOMER SERVICE</h5>
                        <p>Friendly 24/7 customer support</p>
                        </ListGroup.Item>
                    </ListGroup>
                </Col>
                <Col className="d-flex align-self-center text-center">
                <ListGroup className="d-flex align-items-center">
                        <ListGroup className="rounded-circle bg-secondary align-items-center" 
                        style={{width: '80px', height: '80px'}}
                        >
                            <ListGroup.Item className="rounded-circle bg-black m-2" 
                            style={{width: '67px', height: '80px'}}
                            >
                                <Image
                                src={iconSecure}
                                alt="secure icon"
                                style={{width: '40px', height: '40px'}}
                                />
                            </ListGroup.Item>
                            </ListGroup>
                            <ListGroup.Item>
                            <h5>MONEY BACK GUARANTEE</h5>
                            <p>We return money within 30 days</p>
                            </ListGroup.Item>
                    </ListGroup>
                </Col>
            </Row>
        </Container>
    )
}

export default BeforeFooter