import React, { useContext } from "react";
import NavBar from "../../components/shared/NavBar";
import Footer from "../../components/shared/Footer";
import { Container, Row, Table, Form } from "react-bootstrap";
import { CartContext } from "../../context/CartContext";

const CartPage = () => {
    const {carts, dispatch} = useContext( CartContext )
    const handleQuantity = (cart, value) => {
        dispatch({
            action: 'SET_QUANTITY',
            payload: cart
        })
    }
    return (
        <>
        <NavBar />
        <Container className="my-3 ">
            <Row>
                <Table striped bordered hover className="text-center">
                    <thead>
                        <tr>
                            <th>Product</th>
                            <th>Price</th>
                            <th>Quantity</th>
                            <th>Subtotal</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            carts.map((cart, key) => (
                            <tr key={key}>
                            <td>{cart.product}</td>
                            <td>${cart.price}</td>
                            <td>
                                <Form.Control
                                list="quantities"
                                type="number"
                                placeholder="select quantity"
                                defaultValue={cart.quantity}
                                onChange={(e) => handleQuantity(cart, e.target.value)}
                                />
                            </td>
                            <td>${cart.quantity * cart.price}</td>
                        </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </Row>
        </Container>
        <Footer />
        </>
    )
}

export default CartPage