import React from "react";
import NavBar from "../../components/shared/NavBar";
import Footer from "../../components/shared/Footer";
import { Container, ListGroup, Row, Col, InputGroup, Form, Button } from "react-bootstrap";
import { IoCallOutline } from "react-icons/io5";
import { CiMail } from "react-icons/ci";

const ContactPage = () => {
    return (
        <>
        <NavBar />
        <Container>
            <Row className="my-5">
                <Col>
                <ListGroup>
                    <ListGroup.Item >
                    <ListGroup className="d-flex flex-row">
                        <ListGroup.Item 
                        className="text-white bg-danger rounded-circle align-self-center p-1">
                        <IoCallOutline />
                        </ListGroup.Item>
                        <ListGroup.Item>
                            Call To Us
                        </ListGroup.Item>
                    </ListGroup>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        We are available 24/7, 7 days a week.
                    </ListGroup.Item>
                    <ListGroup.Item>
                    Phone: +8801611112222
                    </ListGroup.Item>
                    <ListGroup.Item>
                    <ListGroup className="d-flex flex-row">
                        <ListGroup.Item 
                        className="text-white bg-danger rounded-circle align-self-center p-1">
                        <CiMail />
                        </ListGroup.Item>
                        <ListGroup.Item>
                            Write To US
                        </ListGroup.Item>
                    </ListGroup>
                    </ListGroup.Item>
                    <ListGroup.Item>
                    Fill out our form and we will contact you within 24 hours.
                    </ListGroup.Item>
                    <ListGroup.Item>
                    Emails: customer@exclusive.com
                    </ListGroup.Item>
                    <ListGroup.Item>
                    Emails: support@exclusive.com
                    </ListGroup.Item>
                </ListGroup>
                </Col>
                <Col>
                    <Form className="d-flex flex-column align-items-center">
                        <InputGroup>
                            <Form.Control 
                            placeholder="Your Name"
                            required
                            className="m-2 w-auto "
                            />
                            <Form.Control 
                            placeholder="Email"
                            required
                            className="m-2 w-auto"
                            />
                            <Form.Control 
                            placeholder="Your Phone"
                            required
                            className="m-2 w-auto"
                            />
                        </InputGroup>
                        <Form.Control 
                        placeholder="Your Message"
                        as="textarea"
                        rows={4}
                        className="m-2"
                        />
                       <Button
                       className="btn-danger text-center"
                       >Send Message
                       </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
        <Footer />
        </>
    )
}

export default ContactPage