import React, { useState } from "react";
import {useNavigate} from 'react-router-dom'
import NavBar from "../../components/shared/NavBar";
import Footer from "../../components/shared/Footer";
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import shoppingImage from '../../assets/beatsnoop.png'
import { FcGoogle } from "react-icons/fc";
import axios from 'axios'
import BASE_URL from "../../config";

const SignUp = () => {
    const navigator = useNavigate()
    const [phone, setPhone] = useState('')
    const [disableBtn, setDisableBtn] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [confirmPass, setConfirmPass] = useState('')
    const [password, setPassword] = useState('')
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        password: '',
    })
    const [successMessage, setSuccessMessage] = useState('')
    
    const handleInput = (e) => {
        const {name, value} = e.target
        setFormData({...formData, [name]: value})
    }
    
    const handlePhone = (e) => {
        const {value} = e.target
        setPhone(value)
        const isPhoneNumber = /^((\+?\d{1,3})|[0])\d{10}$/.test(value)
        if (!isPhoneNumber) {
            setErrorMessage('invalid phone number entered')
            setDisableBtn(true)
        } else {
            setFormData({...formData, phone: value})
            setErrorMessage('')
            setDisableBtn(false)
        }
    }

    const handlePassword = (e) => {
        const {value} = e.target
        setPassword(value)
    }
    
    const handleConfirmPassword = (e) => {
        const {value} = e.target
        setConfirmPass(value)
        if (password !== value) {
            setErrorMessage('password did not match!')
            setDisableBtn(true)
        } else {
            setFormData({...formData, password: value})
            setErrorMessage('')
            setDisableBtn(false) 
        }
    }

    const handleSubmit = async(e) => {
        e.preventDefault()
        await axios.post(`${BASE_URL}/account`, formData)
                    .then((response) => {
                        setSuccessMessage(`${response.data.message}!, proceed to login`)
                        setTimeout(() => {
                            navigator('/login')
                        }, 3000)
                    })
                    .catch((error) => {
                        console.log(error.response.data)
                        setErrorMessage(error.response.data || error.message)
                    })
    }

    return (
        <>
        <NavBar />
        <Container>
            <Row className="d-flex flex-row align-items-center gap-3 my-3 justify-content-xl-between ">
                <Col xl={5} lg={5} className="align-items-center">
                    <Image 
                    src={shoppingImage}
                    alt="Shopping Image"
                    className="d-block w-100"
                    />
                </Col>
                <Col xl={6} lg={6} className="d-flex flex-column justify-content-center">
                    <h3 className="text-left">
                        Create an account
                    </h3>
                    <p className="text-left">
                        Enter your details below
                    </p>
                    <Form className="d-flex flex-column align-items-center"
                    onSubmit={handleSubmit}
                    >
                        <Form.Control 
                            type="text"
                            name="name"
                            placeholder="Full Name"
                            className="form-input mb-3"
                            required
                            minLength={5}
                            onChange={handleInput}
                        />
                        <Form.Control 
                            type="text"
                            name="email"
                            placeholder="Email"
                            required
                            className="form-input my-3"
                            value={formData.email}
                            onChange={handleInput}
                        />
                        <Form.Control 
                            type="text"
                            name="phone"
                            placeholder="Phone number"
                            required
                            className="form-input my-3"
                            value={phone}
                            onChange={handlePhone}
                        />
                        <Form.Control 
                            type="password"
                            name="password"
                            placeholder="Password"
                            className="form-input my-3"
                            value={password}
                            required
                            onChange={handlePassword}
                        />
                        <Form.Control 
                            type="password"
                            name="confirmPassword"
                            placeholder="Confirm password"
                            className="form-input my-3"
                            required
                            value={confirmPass}
                            onChange={handleConfirmPassword}
                        />
                        <i className="text-danger">{errorMessage}</i>
                        <i className="text-success">{successMessage}</i>
                        <Button className="btn btn-danger my-2 align-self-center w-100" type="submit" disabled={disableBtn}>Create Account</Button>
                    </Form>
                    <Button
                    className="btn bg-transparent border-1 border-black text-black my-2 w-100 align-self-center">
                        <FcGoogle 
                        className="me-2"
                        />
                        Sign up with Google
                    </Button>
                    <p className="text-center">Already have an account
                        <a href="/login" className="text-black mx-2">Login</a>
                    </p>
                </Col>
            </Row>
        </Container>
        <Footer />
        </>
    )
}

export default SignUp