import React from "react";
import { Container, Row, Col, ListGroup, Image, Button} from "react-bootstrap";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa"
import { CiInstagram, CiTwitter } from "react-icons/ci";
import QRCode from '../../assets/qrcode.png'
import googleplay from '../../assets/googleplay.png'
import appleStore from '../../assets/AppStore.png'

const Footer = () => {
    return (
        <Container className="bg-black" fluid>
            <Row className="py-4">
                <Col>
                    <ListGroup >
                        <ListGroup.Item className="text-white bg-transparent border-0 fw-bold">Exclusive</ListGroup.Item>
                        <ListGroup.Item className="text-white bg-transparent border-0">Subscribe</ListGroup.Item>
                        <ListGroup.Item className="text-white bg-transparent border-0">Get 10% off your first order</ListGroup.Item>
                    </ListGroup>
                </Col>
                <Col>
                    <ListGroup >
                        <ListGroup.Item className="text-white bg-transparent border-0 fw-bold">Support</ListGroup.Item>
                        <ListGroup.Item className="text-white bg-transparent border-0">15, Obakhavwaye Street, Ring Road, Benin City, Edo State.</ListGroup.Item>
                        <ListGroup.Item className="text-white bg-transparent border-0">support@mummyflourishstore.com</ListGroup.Item>
                        <ListGroup.Item className="text-white bg-transparent border-0">+2348039362146</ListGroup.Item>
                    </ListGroup>
                </Col>
                <Col>
                    <ListGroup >
                        <ListGroup.Item className="text-white bg-transparent border-0 fw-bold">Account</ListGroup.Item>
                        <ListGroup.Item className="text-white bg-transparent border-0">My Account</ListGroup.Item>
                        <ListGroup.Item className="text-white bg-transparent border-0">
                            <a href="/login"
                            className="text-decoration-none text-white"
                            >Login/Register</a>
                        </ListGroup.Item>
                        <ListGroup.Item className="text-white bg-transparent border-0">
                            <a href="/cart"
                            className="text-decoration-none text-white"
                            >Cart</a>
                        </ListGroup.Item>
                        <ListGroup.Item className="text-white bg-transparent border-0">
                            <a href="/wishlist"
                            className="text-decoration-none text-white"
                            >Wishlist</a>
                        </ListGroup.Item>
                        <ListGroup.Item className="text-white bg-transparent border-0">Shop</ListGroup.Item>
                    </ListGroup>
                </Col>
                <Col>
                    <ListGroup >
                        <ListGroup.Item className="text-white bg-transparent border-0 fw-bold">Quick link</ListGroup.Item>
                        <ListGroup.Item className="text-white bg-transparent border-0">Privacy Policy</ListGroup.Item>
                        <ListGroup.Item className="text-white bg-transparent border-0">Terms of use</ListGroup.Item>
                        <ListGroup.Item className="text-white bg-transparent border-0">FAQ</ListGroup.Item>
                        <ListGroup.Item className="text-white bg-transparent border-0">
                            <a href="/contactus"
                            className="text-decoration-none text-white"
                            >Contact us</a>
                        </ListGroup.Item>
                    </ListGroup>
                </Col>
                <Col>
                    <ListGroup >
                        <ListGroup.Item className="text-white bg-transparent border-0 fw-bold">Download App</ListGroup.Item>
                        <ListGroup.Item className="text-white bg-transparent border-0">Save $3 with App New User Only</ListGroup.Item>
                        <ListGroup.Item className="text-white bg-transparent border-0">
                            <Row >
                                <Col className="d-flex justify-content-center">
                                   <Image
                                        src={QRCode}
                                        alt="QR Code image"
                                        style={{height: '76px', width: '76px'}}
                                   />
                                </Col>
                                <Col className="d-flex justify-content-center">
                                    <ListGroup>
                                            <Button className="border-0 bg-transparent">
                                                <Image 
                                                src={googleplay}
                                                alt="Google play Icon"
                                                style={{height: '30px', width: '104px'}}
                                                />
                                            </Button>
                                            <Button className="border-0 bg-transparent">
                                                <Image 
                                                src={appleStore}
                                                alt="Apple store Icon"
                                                style={{height: '30px', width: '104px'}}
                                                />
                                            </Button>
                                    </ListGroup>
                                </Col>
                            </Row>
                        </ListGroup.Item>
                        <ListGroup.Item className="bg-transparent d-flex justify-content-between m-2">
                            <a href="#">
                                <FaFacebookF 
                                className="text-white m-2"
                                href=""
                                />
                            </a>
                            <a href="#">
                                <CiTwitter 
                                className="text-white m-2"
                                />
                            </a>
                            <a href="#">
                                <CiInstagram
                                className="text-white m-2"
                                />
                            </a>
                            <a href="#">
                                <FaLinkedinIn
                                className="text-white m-2"
                                />
                            </a>
                        </ListGroup.Item>
                    </ListGroup>
                </Col>
            </Row>
        </Container>
    )
}

export default Footer