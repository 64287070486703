import React, {createContext, useReducer} from 'react'
import gucciBag from '../assets/Light-Gucci-Savoy-medium-duffle-bag.png'
import gammax from '../assets/gammaxx-l240-argb.png'
import GP11 from '../assets/GP11_PRD3.png'


export const WishlistContext = createContext()

const WishlistReducer = (state, action) => {
    switch (action.type) {
        case 'ADD_TO_WISHLIST':
        return {
            ...state,
            carts: [...state.carts, action.payload]
        }
        case 'REMOVE_FROM_WISHLIST':
        default:
            return state
    }
}

const initialState = {

   wishlist: [
    {
        product: 'Gucci duffle bag',
        slashRate: 35,
        image: gucciBag,
        price: 960 
    },
    {
        product: 'GP11 Shooter USB Gamepad',
        slashRate: 40,
        image: GP11,
        price: 880 
    },
    {
        product: 'RGB liquid CPU Cooler',
        slashRate: 25,
        image: gammax,
        price: 1160 
    }
   ]
}


const WishlistProvider = (props) => {
    const [state, dispatch] = useReducer(WishlistReducer, initialState)

    return (
        <WishlistContext.Provider
        value={{
            wishlist: state.wishlist,
            dispatch
        }}
        >
            {props.children}
        </WishlistContext.Provider>
    )
}

export default WishlistProvider