import React, {useState, useEffect} from 'react'
import {Row, Col, Button, Card, Container, ListGroup } from 'react-bootstrap'
import Ps5 from '../../assets/ps5-slim-goedkope-playstation_large.png'
import speakers from '../../assets/speakers.png'
import perfume from '../../assets/perfume.png'
import attractiveWoman from '../../assets/attractive-woman-wearing-hat.png'

const Arrival = () => {
    const sample = [
        {
            name: 'Play station',
            description: 'latest playstation 5',
            image: Ps5
        },
        {
            name: 'Perfume',
            description: 'High quality perfume',
            image: perfume
        },
        {
            name: 'Skin care products',
            description: 'Attractive body skin care',
            image: attractiveWoman
        },
        {
            name: 'Speaker',
            description: 'Enjoy high quality sound',
            image: speakers
        }
    ]
    const [arrivalProducts, setArrivalProducts] = useState(sample)
    useEffect(() => {
        
    })
    return (
        <Container>
            <Row>
                <h5 className='text-danger'>
                <span className="fw-bold mx-3 bg-danger p-1">I</span>
                Featured
                </h5>
            </Row>
            <Row className='d-flex mx-auto align-items-end'>
                <Col xl={11} xs={6} md={4} className='align-self-start'>
                    <h6 className='fw-semibold'>
                    New Arrival
                    </h6>
                </Col>
            </Row>
            <Row className='d-flex flex-row justify-content-center'>
                {
                    arrivalProducts.map((product, index) => (
                        
                <Card className='bg-black m-1'
                style={{width: '570px', height: '600px'}}
                key={index}
                >
                    <Card.Img src={product.image} 
                       className='w-100'
                    />
                    <Card.ImgOverlay className='d-flex align-items-end'>
                        <ListGroup className='bg-transparent'>
                            <ListGroup.Item className='bg-transparent text-white fw-bold'>
                            {product.name}
                            </ListGroup.Item >
                            <ListGroup.Item className='bg-transparent text-white lh-1'>
                            {product.description}
                            </ListGroup.Item>
                            <ListGroup.Item className='bg-transparent text-white'>
                                <Button className='bg-transparent text-white text-decoration-underline border-0'>
                                    Shop Now
                                </Button>
                            </ListGroup.Item>
                        </ListGroup>
                    </Card.ImgOverlay>
                </Card>
                    ))
                }
            </Row>
        </Container>
    )
}

export default Arrival