import React from "react";
import { Popover, OverlayTrigger, Button } from "react-bootstrap";
import { Icons } from "../../icons"
import { useNavigate } from "react-router-dom";
const { CgProfile } = Icons

const Profile = () => {
    const navigator = useNavigate()
    const handleLogout = () => {
        localStorage.removeItem('isLoggedIn')
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        navigator('/login', {state: {message: 'Logout successful'}})
    }
    const popover = (
        <Popover className="bg-secondary border-0  w-auto" id='popover-contained'>
            <Popover.Body
            className="d-flex flex-column gap-1"
            >
                <Button className="btn bg-transparent border-white text-white">Profile</Button>
                <Button className="btn bg-transparent border-danger  " 
                onClick={handleLogout}
                >Logout</Button>
            </Popover.Body>
        </Popover>
    )
    return (
        <OverlayTrigger trigger='click' placement="bottom" overlay={popover}
        >
            <Button variant="transparent">
                <CgProfile 
                style={{width: "1.5em", height: "1.5em"}}
                />
            </Button>
        </OverlayTrigger>
    )
}

export default Profile