import React from "react";
import NavBar from '../../components/shared/NavBar'
import Footer from '../../components/shared/Footer'

const AboutPage = () => {
    return (
        <>
        <NavBar />
        <h1>About Us</h1>
        <Footer />
        </>
      )
}

export default AboutPage