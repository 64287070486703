import React, {useState, useEffect, useContext} from "react";
import { Container, Row, Col, Form, Button, Table, Spinner } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import BASE_URL from "../../config";
import { AppContext } from "../../context/AppContext";

const Category = () => {
    const { categories } = useContext(AppContext)
    const navigator = useNavigate()
    const [errorMessages, setErrorMessages] = useState('')
    const [successMessage, setSuccessMessage] = useState('')
    const [token, setToken] = useState('')
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        id: ''
    })
    useEffect(() => {
        const token = localStorage.getItem('token')
        if (!token) {
            navigator('/login', {error: 'You need to login first!'})
        }
        setToken(token)
    }, [navigator])
    const handleInput = (e) => {
        const {name, value} = e.target
        setFormData({...formData, [name]: value})
    }
    const handleSubmit = async(e) => {
        e.preventDefault()
        setLoading(true)
        if (formData.id) {
            await axios.put(`${BASE_URL}/category/${formData.id}`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then(response => {
                setLoading(false)
                setSuccessMessage(response.data.message)
                setTimeout(() => {
                    setSuccessMessage('')
                }, 2000)
                setErrorMessages('')
                setFormData({
                    name: '',
                    description: '',
                    id: ''
                })
            })
            .catch(error => {
                setLoading(false)
                setSuccessMessage('')
                setErrorMessages(error.response?.data.message)
            })
        } else {
          await  axios.post(`${BASE_URL}/category`, formData, {
            headers: {
                Authorization: `Bearer ${token}`
            }
          })
            .then(response => {
                setLoading(false)
                setSuccessMessage(response.data.message)
                setTimeout(() => {
                    setSuccessMessage('')
                }, 2000)
                setErrorMessages('')
                setFormData({
                    name: '',
                    description: '',
                    id: ''
                })
            })
            .catch(error => {
                setLoading(false)
                setSuccessMessage('')
                if (error.response?.status === 401) {
                    navigator('/login', {state: {message: 'session expired, login to continue!'}})
                }
                setErrorMessages(error.response?.data)
            })
        }
    }
    const editCategory = (category) => {
            setFormData({...formData,
                name: category.name,
                description: category.description,
                id: category._id
            })
    }
    const deleteCategory = async(id) => {
        await  axios.delete(`${BASE_URL}/category/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
          })
            .then(response => {
                setSuccessMessage(response.data.message)
                setTimeout(() => {
                    setSuccessMessage('')
                }, 2000)
                setErrorMessages('')
            })
            .catch(error => {
                setSuccessMessage('')
                if (error.response?.status === 401) {
                    navigator('/login', {state: {message: 'session expired, login to continue!'}})
                }
                setErrorMessages(error.response?.data)
            })
    }
    return (
        <Container className="m-3">
        <Row className="align-items-center justify-content-center ">
            <Col xl={6} lg={6}>
            <Form className="d-flex flex-column gap-3 m-3 align-items-center"
            onSubmit={handleSubmit}
            >
                <Form.Control
                name="name"
                placeholder="Enter category name"
                required
                value={formData.name}
                onChange={handleInput}
                />
                <Form.Control
                name="description"
                placeholder="Enter category description"
                required
                value={formData.description}
                onChange={handleInput}
                />
                <Form.Control
                name="id"
                type="hidden"
                value={formData.id}
                onChange={handleInput}
                />
                {
                        loading && (
                            <Spinner animation="border" role="status" className="d-flex align-self-center justify-content-center ">
                                <span className="visually-hidden"  >Loading...</span>
                            </Spinner>
                        )
                 }
                <i className="text-danger">{errorMessages}</i>
                <i className="text-success">{successMessage}</i>
                <Button type="submit" className="btn btn-danger ">
                    Submit
                </Button>
            </Form>
            </Col>
        </Row>
        <h2 className="text-center">Categories</h2>
        <Row className="align-items-center m-3 ">
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Category Name</th>
                        <th>Description</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                   {categories.map((category) => (
                       <tr key={category._id}>
                           <td>{category.name}</td>
                           <td>{category.description}</td>
                           <td className="d-flex gap-3">
                               <Button className="btn btn-secondary"
                               onClick={()=>editCategory(category)}
                               >Edit</Button>
                               <Button className="btn btn-danger"
                               onClick={()=>deleteCategory(category._id)}
                               >Delete</Button>
                           </td>
                       </tr>
                   ))}
                </tbody>
            </Table>
        </Row>
    </Container>
    );
}

export default Category;