import React, {useState, useEffect, useContext, useCallback} from "react";
import { Container, Row, Col, Form, Button, Table, Dropdown, Spinner } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import BASE_URL from "../../config";
import { AppContext } from "../../context/AppContext";

const SubCategory = () => {
    const navigator = useNavigate()
    const [errorMessages, setErrorMessages] = useState('')
    const [successMessage, setSuccessMessage] = useState('')
    const {categories, subCategories} = useContext(AppContext)
    const [token, setToken] = useState('')
    const [selectedOption, setSelectedOption] = useState('')
    const [filteredOptions, setfilteredOptions] = useState([])
    const [searchText, setSearchText] = useState('')
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState({
        category: '',
        name: '',
        description: '',
        id: '',
        image: null
    })
    const imageRef = useRef(null)
    useEffect(() => {
        const token = localStorage.getItem('token')
        if (!token) {
            navigator('/login', {error: 'You need to login first!'})
        }
        setToken(token)
    }, [navigator])
    const handleSearch = useCallback((e)=> {
        const text = e.target.value
        setSearchText(text)
        const filtered = categories.filter(option => 
            option.name.toLowerCase().includes(text.toLowerCase())
            )
        setfilteredOptions(filtered)
    }, [categories])
    const handleSelectOption = useCallback((option) => {
        setSelectedOption(option.name)
        setFormData(prevFormData =>({...prevFormData, category: option._id}))
        setSearchText('')
    }, [])
    const handleInput = useCallback((e) => {
        const {name, value} = e.target
        setFormData(prevFormData =>({...prevFormData, [name]: value}))
    }, [])
    const handleFileInput = useCallback((e) => {
        setFormData(prevFormData =>({...prevFormData, image: e.target.files[0]}))
    }, [])
    const handleSubmit = useCallback(async(e) => {
        e.preventDefault()
        setLoading(true)
        const newCategory = new FormData()
        newCategory.append('category', formData.category)
        newCategory.append('name', formData.name)
        newCategory.append('description', formData.description)
        newCategory.append('image', formData.image)
        if (formData.id) {
            await axios.put(`${BASE_URL}/subcategory/${formData.id}`, newCategory, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`
                }
            })
            .then(response => {
                setLoading(false)
                setSuccessMessage(response.data.message)
                setTimeout(() => {
                    setSuccessMessage('')
                }, 2000)
                setErrorMessages('')
                setFormData({
                    category: '',
                    name: '',
                    description: '',
                    id: ''
                })
                setSelectedOption('')
                setSearchText('')
                imageRef.current.value = null
            })
            .catch(error => {
                setLoading(false)
                setSuccessMessage('')
                setErrorMessages(error.response?.data.message)
            })
        } else {
          await  axios.post(`${BASE_URL}/subcategory`, newCategory, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            }
          })
            .then(response => {
                setLoading(false)
                setSuccessMessage(response.data.message)
                setTimeout(() => {
                    setSuccessMessage('')
                }, 2000)
                setErrorMessages('')
                setFormData({
                    category: '',
                    name: '',
                    description: '',
                    id: '',
                    image: null
                })
                setSelectedOption('')
                setSearchText('')
                imageRef.current.value = null
            })
            .catch(error => {
                setLoading(false)
                setSuccessMessage('')
                if (error.response?.status === 401) {
                    navigator('/login', {state: {message: 'session expired, login to continue!'}})
                }
                setErrorMessages(error.response?.data)
            })
        }
    }, [formData, token, navigator])
    const editSubCategory = (subcategory) => {
            setFormData({...formData,
                category: subcategory.category,
                name: subcategory.name,
                description: subcategory.description,
                id: subcategory._id
            })
            setSelectedOption(subcategory.category.name)
            setSearchText('')
    }
    const deleteSubCategory = async(id) => {
        await  axios.delete(`${BASE_URL}/subcategory/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
          })
            .then(response => {
                setSuccessMessage(response.data.message)
                setTimeout(() => {
                    setSuccessMessage('')
                }, 2000)
                setErrorMessages('')
            })
            .catch(error => {
                setSuccessMessage('')
                if (error.response?.status === 401) {
                    navigator('/login', {state: {message: 'session expired, login to continue!'}})
                }
                setErrorMessages(error.response?.data)
            })
    }
    return (
        <Container className="m-3">
        <Row className="align-items-center justify-content-center ">
            <Col xl={6} lg={6}>
            <Form className="d-flex flex-column gap-3 m-3 align-items-start"
            onSubmit={handleSubmit}
            >
            <Form.Control
            name="name"
            placeholder="Enter sub-category name"
            required
            value={formData.name}
            onChange={handleInput}
            />
            <Dropdown>
                <Dropdown.Toggle
                className="bg-transparent border-0  text-black"
                >
                    {selectedOption || 'Select Category'}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Form.Control 
                    autoFocus
                    name="category"
                    placeholder="type to filter"
                    value={searchText}
                    onChange={handleSearch}
                    onClick={(e) => e.stopPropagation()}
                    style={{marginBottom: '5px'}}
                    />
                    {filteredOptions.map((option, index )=> (
                        <Dropdown.Item
                        key={index} onClick={() => handleSelectOption(option)}
                        >
                            {option.name}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
            <Form.Control
                name="description"
                placeholder="Enter description of sub-category"
                required
                value={formData.description}
                onChange={handleInput}
                />
                <Form.Control
                name="image"
                type="file"
                ref={imageRef}
                accept="image/*"
                onChange={handleFileInput}
                />
                <Form.Control
                name="id"
                type="hidden"
                value={formData.id}
                onChange={handleInput}
                />
                 {
                        loading && (
                            <Spinner animation="border" role="status" className="d-flex align-self-center justify-content-center ">
                                <span className="visually-hidden"  >Loading...</span>
                            </Spinner>
                        )
                 }
                <i className="text-danger">{errorMessages}</i>
                <i className="text-success">{successMessage}</i>
                <Button type="submit" className="btn btn-danger d-flex align-self-center">
                    Submit
                </Button>
            </Form>
            </Col>
        </Row>
        <h2 className="text-center">Sub-Categories</h2>
        <Row className="align-items-center m-3 ">
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Category Name</th>
                        <th>Sub-Category</th>
                        <th>Description</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                   {subCategories.map((subcategory) => (
                       <tr key={subcategory._id}>
                            {/* <td>{subcategory.category.name}</td> */}
                           <td>{subcategory.name}</td>
                           <td>{subcategory.description}</td>
                           <td className="d-flex gap-3">
                               <Button className="btn btn-secondary"
                               onClick={()=>editSubCategory(subcategory)}
                               >Edit</Button>
                               <Button className="btn btn-danger"
                               onClick={()=>deleteSubCategory(subcategory._id)}
                               >Delete</Button>
                           </td>
                       </tr>
                   ))}
                </tbody>
            </Table>
        </Row>
    </Container>
    );
}

export default SubCategory;