import React, {useContext} from 'react'

import { Row, Col, Container, Card, Button } from 'react-bootstrap'
import { Icons } from "../../icons";
import BASE_URL from '../../config'
import { AppContext } from '../../context/AppContext';

const { FaArrowRight, FaArrowLeft  } = Icons

const Categories = () => {
    const {subCategories} = useContext(AppContext)
    console.log(subCategories)
    return (
        <Container className='my-3'>
            <Row className='d-flex my-1'>
                <h5 className='text-danger'>
                <span className='bg-danger me-3 p-1'>I</span>
                Categories
                </h5>
            </Row>
            <Row className='d-flex justify-content-between'>
                <Col>
                <h6 className='fw-semibold'>Browse By Category</h6>
                </Col>
                <Col xl={1}>
                <Button className="bg-transparent border-0 text-black">
                <FaArrowLeft />
                </Button>
                <Button className="bg-transparent border-0 text-black">
                    <FaArrowRight />
                </Button>
                </Col>
            </Row>
            <Row className='d-flex flex-row gap-3 justify-content-lg-even justify-content-center'>
                    {
                    subCategories.map((category, index) => (
                        <Card style={{width: '170px', height: '145px'}}
                    key={index}
                    >
                        <Card.Link href={`/categories/${category.name}`} className='text-decoration-none align-self-center'>
                        <Card.Img  
                        src={`${BASE_URL}/${category.image}`}
                        alt={category.description}
                        style={{width: '56px', height: '56px'}}
                        className='m-3 align-self-center'
                        />
                        </Card.Link>
                        <Card.Text className='mx-2 text-center'>{category.name}</Card.Text>
                    </Card>
                        ))
                    }
            </Row>
        </Container>
    )
}

export default Categories