import React, { useContext } from "react";
import { Navbar, Nav} from 'react-bootstrap'
import { AppContext } from "../../context/AppContext";

const SideBar = () => {
    const {categories} = useContext(AppContext)
    return (
        <Navbar className="flex-column align-items-stretch">
        <Nav className="flex-column">
            {categories.map((category, index) => (
                <Nav.Link key={index} href={`/categories/${category.name}`}
                >
                    {category.name}
                </Nav.Link>
            ))}
        </Nav>
    </Navbar>
    )
}

export default SideBar