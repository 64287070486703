import React, {createContext, useReducer} from 'react'


export const CartContext = createContext()

const CartReducer = (state, action) => {
    switch (action.type) {
        case 'ADD_TO_CART': 
        return {
            ...state,
            carts: [...state.carts, action.payload]
        }
        case 'GET_TOTAL_PRICE':
            const total = state.carts.reduce((sum, item) =>
            sum + item.price
        )
        return {
            ...state,
            total
        }
        case 'SET_QUANTITY':
            const filteredCarts = state.carts.filter((cart =>
                cart.product !== action.payload.product && 
                cart.price !== action.payload.price
            ))
            return {
                ...state,

                carts: [...filteredCarts, action.payload]
            }
        default:
            return state
    }
}

const initialState = {

   carts: [
    {
        product: 'LCD Monitor',
        quantity: 1,
        price: 650
    }
   ]
}


const CartProvider = (props) => {
    const [state, dispatch] = useReducer(CartReducer, initialState)

    return (
        <CartContext.Provider
        value={{
            carts: state.carts,
            dispatch
        }}
        >
            {props.children}
        </CartContext.Provider>
    )
}

export default CartProvider