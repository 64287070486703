import React from "react";
import { Button, Col, Container, Image, ListGroup, Row} from 'react-bootstrap'
import BoomBox from '../../assets/JBL_BOOMBOX_2_HERO.png'

const BuyNow = () => {
    return (
        <Container>
            <Row className="bg-black p-3 m-3">
                <Col xl={6} className="align-self-center">
                    <ListGroup className="bg-black">
                        <ListGroup.Item className="fw-bold bg-transparent text-success border-0">
                            Categories
                        </ListGroup.Item>
                        <ListGroup.Item  className="experience border-0 bg-transparent text-white" >
                            <h1>Enhance Your <br />
                            Music Experience</h1>
                        </ListGroup.Item>
                        <ListGroup.Item className="bg-transparent border-0">
                            <Row className="align-items-start">
                                <div className="time-circle p-2 text-center mx-1 my-2">
                                    <p className="time-name "><span className="time-digit">23</span><br />
                                    Hours
                                    </p> 
                                </div>
                                <div className="time-circle text-center p-2 mx-1 my-2">
                                    <p className="time-name"><span className="time-digit">05</span><br />
                                        Days
                                        </p> 
                                </div>
                                <div className="time-circle text-center p-2 mx-1 my-2">
                                    <p className="time-name"><span className="time-digit">59</span><br />
                                        Munites
                                        </p> 
                                </div>
                                <div className="time-circle text-center p-2 mx-1 my-2">
                                    <p className="time-name"><span className="time-digit">35</span><br />
                                        Seconds
                                        </p> 
                                </div>
                            </Row>
                        </ListGroup.Item >
                        <ListGroup.Item  className="bg-transparent mt-2 border-0">
                            <Button className="bg-success text-white border-0">
                                Buy Now
                            </Button>
                        </ListGroup.Item>
                    </ListGroup>
                </Col>
                <Col >
                    <Image 
                    src={BoomBox}
                    className="w-100"
                    />
                </Col>
            </Row>
        </Container>
    )
}

export default BuyNow