import React, { useState } from 'react'
import {Row, Col, Button, Card, Container } from 'react-bootstrap'
import { Icons } from '../../icons'
import gamepad from '../../assets/gamepad.png'

const { FaRegEye, FaRegHeart, FaStar } = Icons

const BestSelling = () => {
    const bestSellingProducts = [
        {
            name: 'Game pad',
            description: 'Latest Game pad',
            image: gamepad,
            rating: [1, 2, 3, 4, 5]
        }
    ]
    const [rating, setRating] = useState(2)
    const [products, setProducts] = useState(bestSellingProducts)
    const handleStarClick = (newRating) => {
        setRating(newRating)
    }
    return (
        <Container className='my-4'>
            <Row>
                <h5 className='text-danger'>
                <span className="fw-bold me-3 bg-danger p-1">I</span>
                Today's
                </h5>
            </Row>
            <Row className='d-flex justify-content-between'>
                <Col xl={3}>
                    <h6 className='fw-semibold ff-Inter'>
                    Best Selling Products
                    </h6>
                </Col>
                <Col xl={1}>
                    <Button className='bg-danger p-2'>
                        View All
                    </Button>
                </Col>
            </Row>
            <Row>
                {
                    products.map((product, index)=> (
                    <Card style={{width: "270px", height: "370px", backgroundColor: "#F5F5F5", border: 0}}
                    key={index}
                    >
                        <Card.ImgOverlay>
                            <Row>
                                <Col style={{width: "34px", height: "76px", marginLeft: "224px", gap: "8px", marginTop: "12px"}}>
                                <FaRegHeart />
                                <FaRegEye />
                                </Col>
                            </Row>
                        </Card.ImgOverlay>
                        <Card.Img src={product.image} 
                            style={{width: "175px", height: "152px", margin: '40px'}} />
                        <Card.Body style={{backgroundColor: "white"}}>
                            <Card.Text className='fw-bold'>{product.description}</Card.Text>
                            <Card.Text>
                                <span style={{color: "#DB4444", font: "Poppins"}}>$120</span>
                                <span className='text-decoration-line-through ps-2' style={{color: '#000000', font: "Poppins"}}>$140</span>
                            </Card.Text>
                            {product.rating.map((star) => (
                                <FaStar
                                    key={star}
                                    onClick={() => handleStarClick(star)}
                                    style={{cursor: 'pointer', marginRight: '5px',
                                    color: star <= rating ? '#FFAD33' : '#000000'
                                }}
                                />
                            ))}
                        </Card.Body>
                    </Card>
                    ))
                }
            </Row>
        </Container>
    )
}

export default BestSelling