import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import BASE_URL from "../config";

export const AppContext = createContext();

const AppProvider = (props) => {
    const [categories, setCategories] = useState(() => {
        const savedCategories = localStorage.getItem('categories');
        return savedCategories ? JSON.parse(savedCategories) : [];
    });

    const [subCategories, setSubCategories] = useState(() => {
        const savedSubCategories = localStorage.getItem('subCategories');
        return savedSubCategories ? JSON.parse(savedSubCategories) : [];
    });


    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/category`);
                if (response) {
                    setCategories(response.data.categories);
                    localStorage.setItem('categories', JSON.stringify(response.data.categories));
                }
            } catch (error) {
                console.log(error.message);
            }
        };
        const fetchSubCategories = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/subcategory`);
                if (response) {
                    setSubCategories(response.data.subCategories);
                    localStorage.setItem('subCategories', JSON.stringify(response.data.subCategories));
                }
            } catch (error) {
                console.log(error.message);
            }
        };
            fetchCategories();
            fetchSubCategories();
    }, []);

    return (
        <AppContext.Provider
            value={{
                categories,
                subCategories,
            }}
        >
            {props.children}
        </AppContext.Provider>
    );
};

export default AppProvider;