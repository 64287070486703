import React from "react";
import NavBar from "../../components/shared/NavBar";
import Footer from "../../components/shared/Footer";
import { Row } from "react-bootstrap";

const AccessDenied = () => {
    return (
        <>
        <NavBar />
        <Row className="my-5 justify-content-center ">
            <h1 className="text-center">Access Denied!</h1>
            <p className="text-center">You don't have access to this route.
            </p>
            <a className="text-center w-auto bg-danger text-white p-2 btn" href="/">Back to home page</a>
        </Row>
        <Footer />
        </>
    )
}

export default AccessDenied