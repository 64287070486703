import React, { useContext } from "react";

import { Container, Row, Button, Card } from "react-bootstrap";

import { Icons } from "../../icons"
import { WishlistContext } from "../../context/WishlistContext";

const {RiDeleteBin6Line, MdOutlineShoppingCart } = Icons

const Wishlist = () => {
    const {wishlist} = useContext(WishlistContext)
    return (
        <Container className="my-3">
            <Row className="flex-row align-items-end m-2">
                <p className="col align-self-center">Wishlist({wishlist.length})</p>
                <Button className="bg-transparent w-auto p-2 text-black border-black align-self-center">Move All To Bag</Button>
            </Row>
            <Row className="justify-content-around">
                {
                    wishlist.map((product, key) => (
                        <Card className="border-0 p-0 w-auto m-2"
                style={{backgroundColor: '#F5F5F5'}}
                >
                    <Card.Title className="m-2 d-flex align-items-center justify-content-between">
                        <Card.Text className="bg-danger p-2 w-auto text-white align-self-start">{product.slashRate}%</Card.Text>
                        <a className="text-black p-2">
                            <RiDeleteBin6Line width={34} height={34}/>
                        </a>
                    </Card.Title>
                    <Card.Img 
                    src={product.image}
                    alt="hand bag"
                    className="p-2"
                    style={{width: '178px', height: '150px'}}
                    />
                    <Button 
                    className="d-flex flex-row  justify-content-center align-items-center border-0 bg-black text-white mt-2"
                    >
                        <MdOutlineShoppingCart className="mx-2 d-flex align-self-center my-0"/>
                        <p className="align-self-center mb-0">Add to Cart</p>
                    </Button>
                    <Card.Footer className="bg-white h-100">
                        <h5>{product.product}</h5>
                        <p className="text-danger">${product.price}<span className="text-decoration-line-through text-secondary">$1160</span></p>
                    </Card.Footer>
                </Card>
                    ))
                }
            </Row>
        </Container>
    )
}

export default Wishlist