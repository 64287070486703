import React, {useContext, useEffect, useState} from "react";
import NavBar from "../../components/shared/NavBar";
import Footer from "../../components/shared/Footer";
import { Container, Row } from "react-bootstrap";
import { AppContext } from "../../context/AppContext";
import { useParams } from "react-router-dom";


const CategoryPage = () => {
    const {name} = useParams()
    const {categories} = useContext(AppContext)
    const [category, setCategory] = useState()
    useEffect(() => {
        setCategory(categories.find(category => category.name=== name))
    }, [name, categories])
    return (
        <>
        <NavBar />
        <Container>
            <Row>
                <h1 className="text-danger text-center">{
                    category && category.name
                    }</h1>
            </Row>
        </Container>
        <Footer />
        </>
    )
}

export default CategoryPage