import React, { useContext } from "react";
import {Navbar, Nav, Container, Dropdown, Row,  Image, Button, InputGroup, Form} from 'react-bootstrap';
import logo from '../../assets/logo.png'
import Profile from "./Profile";

import { Icons } from "../../icons"
const {FaRegHeart, IoIosSearch, FiShoppingCart } = Icons

const NavBar = () => {
    const isLoggedIn = localStorage.getItem('isLoggedIn')
    const token = localStorage.getItem('token')
    const user = localStorage.getItem('user')
    return (
        <>
        <Container fluid>
            <Row className="py-1 bg-black flex-row justify-content-around">
                <p className="bg-transparent text-white w-auto col-auto mx-5">Summer Sale For All Swim Suits And Free Express Delivery - OFF 50%!
                <a href="/" className="ps-2 text-decoration-underline text-white">ShopNow</a>
                </p>
                <Dropdown className="col-auto">
                    <Dropdown.Toggle id="dropdown-basic" className="text-white border-0 bg-transparent">
                        English
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="bg-transparent border-0">
                        <Dropdown.Item className="my-2 bg-transparent">English</Dropdown.Item>
                        <Dropdown.Item className="my-2 bg-transparent">Latin</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Row>
            <Navbar expand="lg" className="bg-none align-items-lg-center justify-content-lg-center mx-5 ">
                    <Navbar.Brand href="/" className="mx-lg-5">
                    <Image 
                    src= {logo}
                    width={32}
                    height={32}
                    /> 
                    </Navbar.Brand>
                    <Row className="d-flex flex-row gap-5 align-items-center justify-content-lg-between">
                    <Navbar.Toggle arial-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav>
                            <Nav.Link href="/" className="nav-link mx-xl-3">Home</Nav.Link>
                            <Nav.Link href="/about" className="nav-link mx-xl-3">About</Nav.Link>
                            <Nav.Link href="contactus" className="nav-link mx-xl-3">Contact Us</Nav.Link>
                            {
                                isLoggedIn ? '' : (
                                    <Nav.Link href="/signup" className="nav-link mx-xl-3">Sign Up</Nav.Link>
                                )
                            }
                        </Nav>
                        <Form inline='true'>
                            <InputGroup>
                                <InputGroup.Text className="bg-tertiary py-0">
                                <Form.Control 
                                type="search"
                                className="border-0 bg-tertiary"
                                />
                                <Button className="text-black bg-transparent border-0">
                                    <IoIosSearch />
                                </Button>
                                </InputGroup.Text>
                            </InputGroup>
                        </Form>
                        <Nav className="mb-2 mb-lg-0 align-self-center">
                            <Nav.Link href="/wishlist" className="nav-link mx-xl-3">
                                <FaRegHeart />
                            </Nav.Link>
                            <Nav.Link href="/cart" className="nav-link mx-xl-3">
                                <FiShoppingCart />
                            </Nav.Link>
                            {isLoggedIn ? (<Profile />) : ''}
                        </Nav>
                    </Navbar.Collapse>
                    </Row>
            </Navbar>
            <hr />
        </Container>
        </>
    )
}

export default NavBar